import React from "react";
import styled from 'styled-components';
import { graphql, StaticQuery } from "gatsby";
import Helmet from "react-helmet";
import {DOMAIN, NUM_LATEST_TOPICS} from "../constants";
import ArticlesList from "../components/ArticlesList";
import mainSvg from "../../images/undraw_online_groceries_a02y.svg";
import ogImage from "../../images/banner.png";

const tagline = 'Keeping a look out for delicious and healthy recipes on the net';

const Container = React.memo(styled.main`
  margin: auto;
  text-align: center;
  max-width: 900px;
  
  h1, h2, .tagline {
    text-align: center;
  }
  h1 {
    margin-top: 1.75rem;
    margin-bottom: 0.875rem;
  }
  h2 {
    margin-top: 20px;
  }
  .tagline {
    margin: 0 10px 1.5rem 10px;
  }
  
  .main-svg {
    width: 80%;
    max-width: 270px;
    margin-bottom: 25px;
  }
`);

const IndexPage = React.memo(() => (
  <StaticQuery
  query={ graphql`
    query HomeQuery {
      allDataJson {
        edges {
          node {
            topics {
              id
              title
              slug
              published
              datePublished
              baseFood {
                name
              }
              recipes {
                title
                videoId
              }
            }
          }
        }
      }
    }
` }
  render={ (data) => {
    const node = data.allDataJson.edges[0].node;
    const latestTopics = node['topics'].filter(t => t.published)
                                       .slice(0, NUM_LATEST_TOPICS);
    return (
      <Container>
        <Helmet>
          <title>Recipe Watch</title>
          <meta name="description" content={tagline} />
          <meta property="og:title" content='Recipe Watch' />
          <meta property="og:description" content={tagline} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={ogImage} />
          <link rel="canonical" href={`${DOMAIN}`} />
        </Helmet>
        <h1>Recipe Watch</h1>
        <p className='tagline'>{tagline}</p>
        <img className='main-svg' src={mainSvg} alt="Recipe Watch" />
        <ArticlesList articles={latestTopics} />
      </Container>
    );
}} />));

export default IndexPage
